/**
 * 项目管理
 * */
 export default [
    {
        path: '/projectTasks/projectList',
        component: resolve => require(['../../components/pages/projectTasks/projectList.vue'], resolve),
        meta: {title: 'C端营销活动'}
    },
    {
        path: '/projectTasks/projectCodeBind',
        component: resolve => require(['../../components/pages/projectTasks/projectCodeBind.vue'], resolve),
        meta: {title: '二维码绑定'}
    },
    {
        path: '/projectTasks/projectCodeActive',
        component: resolve => require(['../../components/pages/projectTasks/projectCodeActive.vue'], resolve),
        meta: {title: '二维码激活'}
    },
    {
        path: '/projectTasks/projectCodeActiveLog',
        component: resolve => require(['../../components/pages/projectTasks/projectCodeActiveLog.vue'], resolve),
        meta: {title: '激活记录'}
    },
    {
        path: '/projectTasks/configPrize',
        component: resolve => require(['../../components/pages/projectTasks/configPrize.vue'], resolve),
        meta: {title: '奖项配置'}
    },
    {
        path: '/projectTasks/configPlay',
        component: resolve => require(['../../components/pages/projectTasks/configPlay.vue'], resolve),
        meta: {title: '模型管理'}
    },
    {
        path: '/projectTasks/prizePut',
        component: resolve => require(['../../components/pages/projectTasks/prizePut.vue'], resolve),
        meta: {title: '定投配置'}
    },
    {
        path: '/projectTasks/prizePutLog',
        component: resolve => require(['../../components/pages/projectTasks/prizePutLog.vue'], resolve),
        meta: {title: '定投流水'}
    },
    
    {
        path: '/projectTasks/productList',
        component: resolve => require(['../../components/pages/projectTasks/productList.vue'], resolve),
        meta: {title: '商品列表'}
    },
    {
        path: '/projectTasks/productCategory',
        component: resolve => require(['../../components/pages/projectTasks/product/productCategory.vue'], resolve),
        meta: {title: '商品分类'}
    },
    {
        path: '/projectTasks/prizeList',
        component: resolve => require(['../../components/pages/projectTasks/prizeList.vue'], resolve),
        meta: {title: '奖品类型管理'}
    },
    {
        path: '/projectTasks/settingError',
        component: resolve => require(['../../components/pages/projectTasks/settingError.vue'], resolve),
        meta: {title: '错误页面管理'}
    },
{
        path: '/projectTasks/projectRegionPrize',
        component: resolve => require(['../../components/pages/projectTasks/projectRegionPrize.vue'], resolve),
        meta: {title: '区域奖配'}
    },
    {
        path: '/projectTasks/warningConfig',
        component: resolve => require(['../../components/pages/projectTasks/warningConfig.vue'], resolve),
        meta: {title: '风控预警配置'}
    },
    {
        path: '/projectTasks/templateList',
        component: resolve => require(['../../components/pages/projectTasks/templateList.vue'], resolve),
        meta: {title: '活动模型管理'}
    },
    {
        path: '/projectTasks/templateLog',
        component: resolve => require(['../../components/pages/projectTasks/templateLog.vue'], resolve),
        meta: {title: '模型列表'}
    },
    {
        path: '/projectTasks/templateModel',
        component: resolve => require(['../../components/pages/projectTasks/templateModel.vue'], resolve),
        meta: {title: '模型详情'}
    },

    // 项目列表
    {
        path: '/projectTasks/newProjectList',
        component: resolve => require(['../../components/pages/projectTasks/newProjectList.vue'], resolve),
        meta: {title: '项目列表'}
    },
    {
        path: '/projectTasks/newProjectCreate',
        component: resolve => require(['../../components/pages/projectTasks/newProjectCreate.vue'], resolve),
        meta: {title: '创建项目'}
    },
    {
        path: '/projectTasks/newProjectCode',
        component: resolve => require(['../../components/pages/projectTasks/newProjectCode.vue'], resolve),
        meta: {title: '码包管理'}
    },
    {
        path: '/projectTasks/newProjectCodeActiveLog',
        component: resolve => require(['../../components/pages/projectTasks/newProjectCodeActiveLog.vue'], resolve),
        meta: {title: '激活记录'}
    },
    {
        path: '/projectTasks/newProjectScene',
        component: resolve => require(['../../components/pages/projectTasks/newProjectScene.vue'], resolve),
        meta: {title: '场景管理'}
    },
    {
        path: '/projectTasks/productManage',
        component: resolve => require(['../../components/pages/projectTasks/productManage.vue'], resolve),
        meta: {title: '商品管理'}
    },
    {
        path: '/projectTasks/categoryPrizeList',
        component: resolve => require(['../../components/pages/projectTasks/categoryPrizeList.vue'], resolve),
        meta: {title: '奖品列表'}
    },

    // 实物邮寄订单
    {
        path: '/projectTasks/order/marketing/materialList',
        component: resolve => require(['../../components/pages/projectTasks/order/marketing/materialList.vue'], resolve),
        meta: {title: '实物邮寄订单'}
    },
    {
        path: '/projectTasks/order/marketing/materialInfo',
        component: resolve => require(['../../components/pages/projectTasks/order/marketing/materialInfo.vue'], resolve),
        meta: {title: '实物邮寄订单详情'}
    },
    
]